.rbc-btn-group button + button {
  border: 1px solid #d7dae2 !important;
  border-radius: 0px;
  outline: none;
}
.rbc-toolbar {
  padding: 8px 0 !important;
  font-size: 14px;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #ffffff;
  background-color: #d7dae2 !important;
  border-color: #d7dae2 !important;
}
.rbc-event {
  background-color: #e44b32 !important;
  outline: none !important;
}
.calendar-block {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 120px);
  height: 650px;
  background: #ffffff;
  padding-top: 0px;
  padding-bottom: 30px;
  margin-top: 15px;
}
.rbc-event-content {
  outline: none !important;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #e44b32;
}
.rbc-selected.rbc-selected {
  height: 36px !;
}
.rbc-toolbar button {
  color: #4d4f5c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
  border: 1px solid #d7dae2;
}

.rbc-toolbar button.rbc-active {
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #d7dae2;
  color: #e44b32;
}
.rbc-time-header,
.rbc-overflowing {
  margin-right: 0;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  background: #fff !important;
  border: 1px solid #d7dae2;
  color: #e44b32;
}
.rbc-toolbar button:focus {
  /* background: #00c1de; */
  border: 1px solid #d7dae2;
  outline: none;
  color: #e44b32;
}
.rbc-toolbar button:hover {
  /* background: #00c1de; */
  background-color: #fff !important;
  border: 1px solid #d7dae2;
  color: #e44b32;
}
.rbc-btn-group > button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.text-left {
  width: 48%;
  margin-right: 16px;
}
.nomrgn {
  margin-right: 0px;
}
.customfooter .fa-trash {
  justify-content: left;
  float: left;
  font-size: 17px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 14px;
  cursor: pointer;
}
/* .customfooter {
  display: inline;
} */
.customfooter i {
  display: inline;
}
.text-left {
  padding-top: 6px;
}
.text-left label {
  justify-content: left;
}
.text-left.nomrgn {
  width: 48%;
  padding-top: 0px !important;
}
.text-left.nomrgn label {
  justify-content: left;
  text-align: left;
}
.text-left.nomrgn input {
  width: 100%;
  cursor: pointer;
}
#calendar-title {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
@media (max-width: 991px) {
  #calendar-title {
    width: 70%;
  }
}
input[type="text"]#calendar-title {
  font-size: 20px;

  /* color: #686868; */
}
input[type="text"]#calendar-title::-webkit-input-placeholder {
  font-size: 21px;
  color: #c4c5c6;
}

input[type="text"]#calendar-title:focus {
  /* border: 1px solid #ced4da; */
  background: #fff;
  color: #204b5f;
  border-radius: 3px;
  padding: 0px 8px;
  width: 80%;
}
.o_day-picker {
  bottom: 0%;
  left: 0%;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  border: 1px solid #e3e3ea;
  margin-top: 16px;
}

.o_selected-current-channel-day {
  background: rgba(238, 119, 67, 0.45) !important;
}
.o_day-picker .i_day-picker-row [class*="-day"]:hover {
  background: rgba(238, 119, 67, 0.45) !important;
}

.calendar-modalbody {
  padding: 16px 32px !important;
}
.edittext-wrapper {
  width: 100%;
}
.edittext-wrapper > i {
  cursor: pointer;
}

/* ui for the search tag with border*/

.calendarselect > div {
  border: 1px solid #ced4da !important;
}
.calendarselect .css-151xaom-placeholder {
  padding-left: 0px;
}
.rbc-today {
  background: #f0f0f7;
}

.rbc-toolbar-label {
  font-weight: 600;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none;
}

.rbc-header {
  padding: 10px;
  border: none !important;
}

.rbc-time-view .rbc-row {
  min-height: auto;
}
